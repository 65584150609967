import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { EnvironmentNumberFormatService } from '@oper-client/shared/util-formatting';
import { DestroyableComponent } from '@shared/util-component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'oper-client-input-currency',
	styleUrls: ['./input-currency.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="input-group__addition input-group__addition--prepend">
			<div class="symbol">
				{{ environmentCurrencySymbol }}
			</div>
		</div>
		<input
			operClientNumberToDecimalDirective
			[options]="defaultCurrencyInputOptions$ | async"
			class="input-group__form-input"
			[ngClass]="inputClasses"
			[ngModel]="value"
			[required]="required"
			[disabled]="disabled"
			[readonly]="disabled"
			(valueChanged)="changed.emit($event)"
		/>
	`,
})
export class InputCurrencyComponent extends DestroyableComponent {
	@HostBinding('class.disabled') @Input() disabled: boolean;
	@Input() value: number;
	@Input() required: boolean;
	@Output() changed = new EventEmitter<number>();

	readonly environmentCurrencySymbol = this.environmentNumberFormatService.getCurrencySymbol();
	readonly defaultCurrencyInputOptions$ = this.environmentNumberFormatService.defaultCurrencyInputOptions$.pipe(takeUntil(this.changed));

	constructor(private readonly environmentNumberFormatService: EnvironmentNumberFormatService) {
		super();
	}

	get inputClasses() {
		return {
			'input-group__form-input--disabled': this.disabled,
			'input-group__form-input--padding-left-small': this.environmentCurrencySymbol.length === 1,
			'input-group__form-input--padding-left-medium': this.environmentCurrencySymbol.length === 2,
			'input-group__form-input--padding-left-large': this.environmentCurrencySymbol.length === 3,
		};
	}
}
