import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { takeUntil } from 'rxjs';

import { DestroyableComponent } from '@shared/util-component';
import { EnvironmentNumberFormatService } from '@oper-client/shared/util-formatting';

@Component({
	selector: 'oper-client-input-percentage',
	styleUrls: ['./input-percentage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="input-group__addition input-group__addition--prepend">
			<div class="symbol">%</div>
		</div>
		<input
			operClientNumberToDecimalDirective
			class="input-group__form-input"
			[ngClass]="{ 'input-group__form-input--disabled': disabled }"
			[ngModel]="value"
			[required]="required"
			[disabled]="disabled"
			[readonly]="disabled"
			[options]="defaultPercentageInputOptions$ | async"
			(valueChanged)="changed.emit($event)"
		/>
	`,
})
export class InputPercentageComponent extends DestroyableComponent {
	@HostBinding('class.disabled') @Input() disabled: boolean;
	@Input() value: number;
	@Input() required: boolean;
	@Input() numberOfDecimals = 2;
	@Output() changed = new EventEmitter<number>();

	readonly defaultPercentageInputOptions$ = this.environmentNumberFormatService.defaultPercentageInputOptions$.pipe(
		takeUntil(this.destroy$)
	);

	constructor(private readonly environmentNumberFormatService: EnvironmentNumberFormatService) {
		super();
	}
}
