import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, OnInit, output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AmountAllocation } from '@oper-client/shared/data-model';
import { AsyncPipe } from '@angular/common';
import { UiModule } from '../../ui.module';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { debounceTimes } from '@oper-client/shared/configuration';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'oper-client-product-allocations-form',
	standalone: true,
	imports: [ReactiveFormsModule, AsyncPipe, UiModule, TranslateModule],
	templateUrl: './product-allocations-form.component.html',
	styleUrl: './product-allocations-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAllocationsFormComponent implements OnInit {
	loansAllocationForm?: FormGroup;
	allocations = input<AmountAllocation[]>();
	loadDefaultOffers = output<AmountAllocation[]>();
	totalLoanAmount = computed(() => this.allocations().reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0));
	readonly inputsDebounceTime = debounceTimes.l;

	public baseProductAmountSubject = new Subject<number>();
	public bulletProductAmountSubject = new Subject<number>();
	public baseProductPercentageSubject = new Subject<number>();
	public bulletProductPercentageSubject = new Subject<number>();

	private _destroyRef = inject(DestroyRef);

	constructor(private readonly formBuilder: FormBuilder) {
		effect(() => {
			const baseProductAmount = this.allocations()?.[0]?.amount ?? 0;
			const bulletProductAmount = this.allocations()?.[1]?.amount ?? 0;
			this.loansAllocationForm = this.formBuilder.group({
				baseProductAmount: [
					baseProductAmount,
					Validators.compose([Validators.min(0), Validators.max(baseProductAmount + bulletProductAmount)]),
				],
				bulletProductAmount: [
					bulletProductAmount,
					Validators.compose([Validators.min(0), Validators.max(baseProductAmount + bulletProductAmount)]),
				],
				baseProductPercentage: [null, Validators.compose([Validators.min(0), Validators.max(100)])],
				bulletProductPercentage: [null, Validators.compose([Validators.min(0), Validators.max(100)])],
			});

			this.loansAllocationForm.patchValue(
				{
					baseProductPercentage: this.getBaseProductPercentage(),
					bulletProductPercentage: 100 - this.getBaseProductPercentage(),
				},
				{ emitEvent: false }
			);
		});
	}

	get baseProductAmount(): AbstractControl | undefined {
		return this.loansAllocationForm?.controls['baseProductAmount'];
	}

	get bulletProductAmount(): AbstractControl | undefined {
		return this.loansAllocationForm?.controls['bulletProductAmount'];
	}

	get baseProductPercentage(): AbstractControl | undefined {
		return this.loansAllocationForm?.controls['baseProductPercentage'];
	}

	get bulletProductPercentage(): AbstractControl | undefined {
		return this.loansAllocationForm?.controls['bulletProductPercentage'];
	}

	ngOnInit(): void {
		this.baseProductAmountSubject
			.pipe(debounceTime(this.inputsDebounceTime), takeUntilDestroyed(this._destroyRef))
			.subscribe((newValue: number) => {
				this.loansAllocationForm?.patchValue(
					{
						baseProductAmount: newValue,
						bulletProductAmount: this.totalLoanAmount() - newValue,
					},
					{ emitEvent: false }
				);

				this.updatePercentagesBasedOnAmounts();
				this.loadDefaultOffers.emit(this.getAmountAllocations('baseProduct'));
			});

		this.bulletProductAmountSubject
			.pipe(debounceTime(this.inputsDebounceTime), takeUntilDestroyed(this._destroyRef))
			.subscribe((newValue: number) => {
				this.loansAllocationForm?.patchValue(
					{
						bulletProductAmount: newValue,
						baseProductAmount: this.totalLoanAmount() - newValue,
					},
					{ emitEvent: false }
				);

				this.updatePercentagesBasedOnAmounts();
				this.loadDefaultOffers.emit(this.getAmountAllocations('bulletProduct'));
			});

		this.baseProductPercentageSubject
			.pipe(debounceTime(this.inputsDebounceTime), takeUntilDestroyed(this._destroyRef))
			.subscribe((newValue: number) => {
				this.loansAllocationForm?.patchValue(
					{
						baseProductPercentage: newValue,
						bulletProductPercentage: 100 - newValue,
					},
					{ emitEvent: false }
				);
				this.updateAmountsBasedOnPercentage();
				this.loadDefaultOffers.emit(this.getAmountAllocations('baseProduct'));
			});

		this.bulletProductPercentageSubject
			.pipe(debounceTime(this.inputsDebounceTime), takeUntilDestroyed(this._destroyRef))
			.subscribe((newValue: number) => {
				this.loansAllocationForm?.patchValue(
					{
						bulletProductPercentage: newValue,
						baseProductPercentage: 100 - newValue,
					},
					{ emitEvent: false }
				);
				this.updateAmountsBasedOnPercentage();
				this.loadDefaultOffers.emit(this.getAmountAllocations('bulletProduct'));
			});
	}

	private getTotalProductsAmount(): number {
		const baseProductAmount = this.baseProductAmount?.value ?? 0;
		const bulletProductAmount = this.bulletProductAmount?.value ?? 0;
		return baseProductAmount + bulletProductAmount;
	}

	private getBaseProductPercentage(): number {
		const total = this.getTotalProductsAmount();
		const baseProductAmount = this.baseProductAmount?.value;
		return total > 0 ? Math.max((baseProductAmount / total) * 100, 0) : 0;
	}

	private updateAmountsBasedOnPercentage(): void {
		const total = this.getTotalProductsAmount();
		const baseProductPercentage = this.baseProductPercentage?.value ?? 0;
		const bulletProductPercentage = this.bulletProductPercentage?.value ?? 0;

		this.loansAllocationForm?.patchValue(
			{
				baseProductAmount: (baseProductPercentage / 100) * total,
				bulletProductAmount: (bulletProductPercentage / 100) * total,
			},
			{ emitEvent: false }
		);
	}

	private updatePercentagesBasedOnAmounts(): void {
		const total = this.getTotalProductsAmount();
		const baseProductAmount = this.baseProductAmount?.value ?? 0;
		const bulletProductAmount = this.bulletProductAmount?.value ?? 0;

		this.loansAllocationForm?.patchValue(
			{
				baseProductPercentage: (baseProductAmount * 100) / total,
				bulletProductPercentage: (bulletProductAmount * 100) / total,
			},
			{ emitEvent: false }
		);
	}

	private getAmountAllocations(overriddenProduct: ProductType): AmountAllocation[] {
		const baseProduct: AmountAllocation = this.allocations()[0];
		const bulletProduct: AmountAllocation = this.allocations()[1];

		return [
			{
				...baseProduct,
				amount: Math.round(this.baseProductAmount?.value),
				isOverridden: overriddenProduct === 'baseProduct',
			},
			{
				...bulletProduct,
				amount: Math.round(this.bulletProductAmount?.value),
				isOverridden: overriddenProduct === 'bulletProduct',
			},
		];
	}
}

type ProductType = 'baseProduct' | 'bulletProduct';
