import { FinancialPlanLoanAmountItem, FinancialPlanOwnFundItem } from '../models/financial-plan.model';
import { Resource, ResourceExtended } from '../models/resource.model';
import { Simulator } from '../models/simulator.models';

type PersonalDetails = Pick<
	Simulator.Borrower,
	'birthDate' | 'numberOfDependents' | 'firstName' | 'lastName' | 'emails' | 'phoneNumbers' | 'id'
>;

type BridgeLoan = Pick<Simulator.CostDetails, 'amount'>;

export interface BorrowerSimulationDto {
	realtyType: Resource;
	region: Resource;
	realtyUsageType: Resource;
	purchaseSaleType: Resource;
	epcBeforeRenovations: number;
	epcAfterRenovations: number;
	isMarkedForRenovation: boolean;
	renovationCost: Simulator.CostDetails;
	renovationPurpose: Resource;
	priceBuilding: number;
	architectFees: number;
	isBuyingLand: boolean;
	landPurchaseType: Resource;
	priceOfLand: number;
	priceOfLandUnderLoan: number;
	outstandingLoanAmount: number;
	hasExistingLoanOnLand: boolean;
	interestRateOnLoan: number;
	duration: number;
	totalLoanAmountAtBeginning: number;
	buyOutAmount: number;
	realtyPrice: number;
	venalValueBefore: number;
	venalValueAfter: number;
	loanPurpose: Resource;
	ownFunds: number;
	financialPlanLoanAmountItems: FinancialPlanLoanAmountItem[];
	financialPlanOwnFundItems: FinancialPlanOwnFundItem[];
	numberOfBorrowers: Partial<ResourceExtended>;
	mainBorrowerPersonalDetails: PersonalDetails;
	mainBorrowerLiabilities: Simulator.Liability[];
	mainBorrowerIncomes: Simulator.Income[];
	coBorrowerPersonalDetails: PersonalDetails;
	coBorrowerLiabilities: Simulator.Liability[];
	coBorrowerIncomes: Simulator.Income[];
	extraCollateral: Simulator.Collateral;
	bridgeLoan: BridgeLoan;
}

/**
 * This function returns the list of loan purposes from DTO based on the user's input.
 * @param dto - The borrower simulation DTO.
 * @returns - The list of loan purposes or undefined.
 */
export function getLoanPurposesFromDto(dto: Partial<BorrowerSimulationDto>): Partial<Resource>[] | undefined {
	const loanPurposes = dto.loanPurpose ? [dto.loanPurpose] : undefined;

	// Add renovation purpose if renovation cost is greater than 0 and loan purpose is different from renovation purpose.
	if (loanPurposes && dto.renovationCost?.amount > 0 && dto.renovationPurpose && dto.loanPurpose?.id !== dto.renovationPurpose?.id) {
		loanPurposes.push(dto.renovationPurpose);
	}

	return loanPurposes;
}
