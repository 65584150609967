import { Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	Client,
	getStepsWhichHaveSavedDataPoints,
	Income,
	LoanRequest,
	Realty,
	Step,
	StepData,
	StepperConfiguration,
} from '@oper-client/shared/data-model';
import * as Actions from './loan-application.actions';
import * as Selectors from './loan-application.selectors';
import { toSignal } from '@angular/core/rxjs-interop';
import { ILoanApplicationFacade, LoanApplicationFeatureConfiguration } from '../../interface/loan-application-feature.interface';
import { map } from 'rxjs';

@Injectable()
export class LoanApplicationFacade implements ILoanApplicationFacade {
	constructor(private store: Store<any>) {}

	readonly configuration$ = this.store.pipe(select(Selectors.getConfiguration));
	readonly dataPoints$ = this.store.pipe(select(Selectors.getDataPoints));
	readonly data$ = this.store.pipe(select(Selectors.getData));
	readonly activeStep$ = this.store.pipe(select(Selectors.getActiveStep));
	readonly configuredSteps$ = this.store.pipe(select(Selectors.getSteps));
	readonly loadSimulationActionState$ = this.store.pipe(select(Selectors.getLoadSimulationActionState));
	readonly loadLoanRequestActionState$ = this.store.pipe(select(Selectors.getLoadLoanRequestActionState));
	readonly loadClientsActionState$ = this.store.pipe(select(Selectors.getLoadClientsActionState));
	readonly loadRealtiesActionState$ = this.store.pipe(select(Selectors.getLoadRealtiesActionState));
	readonly loadOffersActionState$ = this.store.pipe(select(Selectors.getLoadOffersActionState));
	readonly updateLoanRequestActionState$ = this.store.pipe(select(Selectors.getUpdateLoanRequestActionState));
	readonly updateClientActionState$ = this.store.pipe(select(Selectors.getUpdateClientActionState));
	readonly updateRealtyActionState$ = this.store.pipe(select(Selectors.getUpdateRealtyActionState));
	readonly updateCollateralRealtyActionState$ = this.store.pipe(select(Selectors.getUpdateCollateralRealtyActionState));
	readonly loadClientLiabilitiesActionState$ = this.store.pipe(select(Selectors.getLoadClientLiabilitiesActionState));
	readonly loadClientIncomesActionState$ = this.store.pipe(select(Selectors.getLoadClientIncomesActionState));

	getActiveStep(): Signal<Step> {
		return toSignal(this.activeStep$);
	}

	getSteps(): Signal<Step[]> {
		return toSignal(this.configuredSteps$);
	}

	getCompletedStepNames(): Signal<string[]> {
		return toSignal(this.dataPoints$.pipe(map((dataPoints) => getStepsWhichHaveSavedDataPoints(dataPoints))));
	}

	getConfiguration?(): Signal<StepperConfiguration> {
		return toSignal(this.configuration$);
	}

	setActiveStep(step: Step): void {
		this.store.dispatch(Actions.setActiveStep({ step }));
	}

	updateActiveStep(changes: Partial<Step>) {
		this.store.dispatch(Actions.updateActiveStep({ changes }));
	}

	setConfiguration(configuration: LoanApplicationFeatureConfiguration): void {
		this.store.dispatch(Actions.setConfiguration({ configuration }));
	}

	updateConfiguration(changes: Partial<LoanApplicationFeatureConfiguration>) {
		this.store.dispatch(Actions.updateConfiguration({ changes }));
	}

	nextStep(): void {
		this.store.dispatch(Actions.nextStep());
	}

	previousStep(): void {
		this.store.dispatch(Actions.prevStep());
	}

	setDataForStep<T>(stepData: StepData<T>): void {
		this.store.dispatch(Actions.setDataForStep({ ...stepData }));
	}

	setData<T>(data: Partial<T>): void {
		this.store.dispatch(Actions.setData({ data }));
	}

	reset(): void {
		this.store.dispatch(Actions.reset());
	}

	clearData(): void {
		this.store.dispatch(Actions.clearData());
	}

	loadSimulation(simulationId: number) {
		this.store.dispatch(Actions.loadSimulation({ simulationId }));
	}

	loadLoanRequest(loanRequestId: number): void {
		this.store.dispatch(Actions.loadLoanRequest({ loanRequestId }));
	}

	loadOffers(loanRequestId: number): void {
		this.store.dispatch(Actions.loadOffers({ loanRequestId }));
	}

	loadClients(loanRequestId: number): void {
		this.store.dispatch(Actions.loadClients({ loanRequestId }));
	}

	loadRealties(loanRequestId: number): void {
		this.store.dispatch(Actions.loadRealties({ loanRequestId }));
	}

	updateLoanRequest(loanRequestId: number, loanRequest: Partial<LoanRequest>): void {
		this.store.dispatch(Actions.updateLoanRequest({ update: { id: loanRequestId, changes: loanRequest } }));
	}

	loadClientIncomes(loanRequestId: number, clientId: number): void {
		this.store.dispatch(Actions.loadClientIncomes({ loanRequestId, clientId }));
	}

	loadClientLiabilities(loanRequestId: number, clientId: number): void {
		this.store.dispatch(Actions.loadClientLiabilities({ loanRequestId, clientId }));
	}

	updateClient(loanRequestId: number, clientId: number, client: Partial<Client>): void {
		this.store.dispatch(Actions.updateClient({ loanRequestId, client: { id: clientId, changes: client } }));
	}

	addClientIncome(loanRequestId: number, clientId: number, income: Partial<Income>): void {
		this.store.dispatch(Actions.addClientIncome({ loanRequestId, clientId, income }));
	}

	updateClientIncome(loanRequestId: number, clientId: number, incomeId: number, income: Partial<Income>): void {
		this.store.dispatch(Actions.updateClientIncome({ loanRequestId, clientId, incomeId, income }));
	}

	removeClientIncome(loanRequestId: number, clientId: number, incomeId: number): void {
		this.store.dispatch(Actions.removeClientIncome({ loanRequestId, clientId, incomeId }));
	}

	addClientExpense(loanRequestId: number, clientId: number, expense: Partial<Income>): void {
		this.store.dispatch(Actions.addClientExpense({ loanRequestId, clientId, expense }));
	}

	updateClientExpense(loanRequestId: number, clientId: number, expenseId: number, expense: Partial<Income>): void {
		this.store.dispatch(Actions.updateClientExpense({ loanRequestId, clientId, expenseId, expense }));
	}

	removeClientExpense(loanRequestId: number, clientId: number, expenseId: number): void {
		this.store.dispatch(Actions.removeClientExpense({ loanRequestId, clientId, expenseId }));
	}

	updateRealty(loanRequestId: number, realtyId: number, realty: Partial<Realty>): void {
		this.store.dispatch(Actions.updateRealty({ loanRequestId, realty: { id: realtyId, changes: realty } }));
	}

	updateCollateralRealty(loanRequestId: number, realtyId: number, realty: Partial<Realty>): void {
		this.store.dispatch(Actions.updateCollateralRealty({ loanRequestId, realty: { id: realtyId, changes: realty } }));
	}
}
