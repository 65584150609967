import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalPricingSheet, PricingSheetProduct } from '@oper-client/configuration-portal/common';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, map, Observable } from 'rxjs';
import { FormConfiguration } from '../../models/dynamic-form.model';

export default function (
	formData?: ConfigurationPortalPricingSheet,
	resources?: PartialNormalizedResource,
	products?: PricingSheetProduct[],
	isEditing = false,
	isDuplicating = false
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'product.configurationId',
			value: formData?.product?.configurationId,
			label: 'ç.feature.offer.product.singular',
			defaultLabel: 'ç.feature.offer.product.singular',
			required: true,
			validators: [],
			options: products?.map((product) => ({
				key: product.name + ' - ' + product.creditProvider?.summary,
				id: product.configurationId,
			})),
			alreadySorted: true,
			class: 'span8',
			disabled: isEditing,
		}),
		new InputField({
			key: 'version',
			label: 'ç.question.version.label',
			value: formData?.version || '',
			type: 'text',
			required: true,
			class: 'span2',
			disabled: !isEditing && !isDuplicating,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'version');
				const formControl = <FormControl>formGroup.controls['version'];
				return formGroup.controls['product.configurationId'].valueChanges.pipe(
					filter((value) => value !== null),
					map((value) => {
						if (value) {
							inputField.disabled = false;
							formControl.enable();
						} else {
							inputField.disabled = true;
							formControl.disable();
						}
						return inputField;
					})
				);
			},
		}),

		new InputField({
			key: 'validFrom',
			label: 'ç.feature.pricing.validFrom',
			value: formData?.validFrom,
			type: 'date',
			required: true,
			validators: [],
			class: 'span2',
			disabled: !isEditing && !isDuplicating,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'validFrom');
				const formControl = <FormControl>formGroup.controls['validFrom'];
				return formGroup.controls['product.configurationId'].valueChanges.pipe(
					filter((value) => value !== null),
					map((value) => {
						if (value) {
							inputField.disabled = false;
							formControl.enable();
						} else {
							inputField.disabled = true;
							formControl.disable();
						}
						return inputField;
					})
				);
			},
		}),

		new InputField({
			key: 'referenceIndex',
			label: 'ç.question.referenceValue.label',
			value: '0',
			type: 'hidden',
			required: true,
			validators: [],
			class: 'span12',
		}),
	];
}
