import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentLibraryActions from './document-library.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

import {
	DocumentLibrary,
	PresignedUrl,
	ActionState,
	ActionTypes,
	initialActionState,
	setActionState,
} from '@oper-client/shared/data-model';

export const documentLibraryFeatureKey = 'documentLibrary';

export interface DocumentLibraryState extends EntityState<DocumentLibrary> {
	presignedUrl: PresignedUrl;
	actions: DocumentLibraryActionsState;
}

export interface DocumentLibraryPartialState {
	readonly [documentLibraryFeatureKey]: DocumentLibraryState;
}

type DocumentLibraryActionTypes = 'loadDocumentLibraries' | 'loadDocumentPreSignedUrl' | 'createDocumentLibrary';
export type DocumentLibraryActionsState = Record<DocumentLibraryActionTypes, ActionState>;

export const adapter: EntityAdapter<DocumentLibrary> = createEntityAdapter<DocumentLibrary>();

export const initialState: DocumentLibraryState = adapter.getInitialState({
	presignedUrl: null,
	actions: {
		loadDocumentLibraries: initialActionState,
		loadDocumentPreSignedUrl: initialActionState,
		createDocumentLibrary: initialActionState,
	},
});

function setActionStates(
	actionState: DocumentLibraryActionsState,
	action: DocumentLibraryActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): DocumentLibraryActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const documentLibraryReducer = createReducer(
	initialState,
	on(DocumentLibraryActions.resetDocumentsLibraryState, () => initialState),
	on(DocumentLibraryActions.loadDocumentLibrarys, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDocumentLibraries', ActionTypes.loading),
	})),
	on(DocumentLibraryActions.loadDocumentLibrarysSuccess, (state, { documentLibraries }) => {
		return adapter.setAll(documentLibraries, {
			...state,
			actions: setActionStates(state.actions, 'loadDocumentLibraries', ActionTypes.success),
		});
	}),

	on(DocumentLibraryActions.loadDocumentLibrarysFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDocumentLibraries', ActionTypes.failure, error),
	})),

	on(DocumentLibraryActions.createDocumentLibrary, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createDocumentLibrary', ActionTypes.loading),
	})),
	on(DocumentLibraryActions.createDocumentLibrarySuccess, (state, { document }) =>
		adapter.addOne(document, { ...state, actions: setActionStates(state.actions, 'createDocumentLibrary', ActionTypes.success) })
	),
	on(DocumentLibraryActions.createDocumentLibraryFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createDocumentLibrary', ActionTypes.failure, error),
	})),
	on(DocumentLibraryActions.deleteDocumentLibrarySuccess, (state, { document }) => adapter.removeOne(document.id, state)),

	on(DocumentLibraryActions.loadDocumentPresignedUrl, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDocumentPreSignedUrl', ActionTypes.loading),
	})),
	on(DocumentLibraryActions.loadDocumentPresignedUrlSuccess, (state, { presignedUrl }) => ({
		...state,
		presignedUrl,
		actions: setActionStates(state.actions, 'loadDocumentPreSignedUrl', ActionTypes.success),
	})),
	on(DocumentLibraryActions.loadDocumentLibrarysFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDocumentPreSignedUrl', ActionTypes.failure, error),
	}))
);

export function reducer(state: DocumentLibraryState | undefined, action: Action) {
	return documentLibraryReducer(state, action);
}

export const { selectEntities, selectAll } = adapter.getSelectors();
