import { createAction, props } from '@ngrx/store';
import { BorrowerSimulationDto, Offer, Resource, Simulator, Step } from '@oper-client/shared/data-model';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { FormConfiguration } from '@oper-client/shared/util-dynamic-form';
import { HttpErrorResponse } from '@angular/common/http';

export const setActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Set Active Step', props<{ step: Step }>());
export const updateActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Update Active Step', props<{ changes: Partial<Step> }>());
export const setConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Configuration',
	props<{ configuration: BorrowerSimulatorFeatureConfiguration }>()
);
export const updateConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Configuration',
	props<{ changes: Partial<BorrowerSimulatorFeatureConfiguration> }>()
);
export const nextStep = createAction('[BorrowerSimulatorApplicationFlow] Next Step');
export const prevStep = createAction('[BorrowerSimulatorApplicationFlow] Previous Step');
export const reset = createAction('[BorrowerSimulatorApplicationFlow] Reset');
export const clearData = createAction('[BorrowerSimulatorApplicationFlow] Clear Data');
export const setData = createAction('[BorrowerSimulatorApplicationFlow] Set Data', props<{ data: Partial<BorrowerSimulationDto> }>());
export const setDataForStep = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Data For Step',
	props<{ step: string; data: Partial<any> }>()
);
export const setStepFormConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Step Form Configuration',
	props<{ configuration: FormConfiguration }>()
);

export const setSelectedPurpose = createAction('[BorrowerSimulatorApplicationFlow] Set Selected Purpose', props<{ purpose: Resource }>());

export const calculateSimulation = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation',
	props<{ payload: Simulator.CalculateSimulationPayload }>()
);

export const calculateSimulationSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation Success',
	props<{ result: Simulator.CalculatedSimulationResult; payload: Partial<Simulator.CalculateSimulationPayload> }>()
);

export const calculateSimulationFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadDefaultOffers = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Offers',
	props<{ payload: Simulator.CalculateSimulationPayload }>()
);

export const loadDefaultOffersSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Offers Success',
	props<{ offers: Partial<Offer>[] }>()
);

export const loadDefaultOffersFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Offers Failure',
	props<{ error: HttpErrorResponse }>()
);

export const setSimulation = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Simulation',
	props<{ simulation: Simulator.Simulation }>()
);

export const setSelectedBorrowerMode = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Selected Borrower Mode',
	props<{ borrowerMode: Resource }>()
);

export const updateData = createAction('[BorrowerSimulatorApplicationFlow] Update Data', props<{ data: Partial<BorrowerSimulationDto> }>());
