<div class="allocation-ratio-bar">
	<div class="allocation-labels">
		@for (allocation of allocations(); track allocation.productId) {
			<div class="allocation-label">
				<span class="text__indicator" [style.background-color]="productColors[$index]"></span>
				{{ allocation.name }}
			</div>
		}
	</div>
	<div class="amount-container">
		@for (allocation of allocations(); track allocation.productId) {
			<div class="used" [style.width.%]="calculateProgress(allocation.amount)" [style.background-color]="productColors[$index]"></div>
		}
	</div>
	<div class="allocation-description">
		@for (allocation of allocations(); track allocation.productId) {
			<div class="allocation-description__column">
				<p>{{ allocation.amount | environmentCurrency }} ({{ calculateProgress(allocation.amount) }}%)</p>
				@if (allocation.duration) {
					<p>{{ allocation.duration / 12 }} {{ 'ç.misc.duration.year.plural' | translate | lowercase }}</p>
				}
			</div>
		}
	</div>
</div>
