import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import {
	DocumentResponse,
	ConfigurationPortalDocumentPayload,
	ExcelPricerResponse,
	ConfigurationPortalLibraryDocumentPayload,
	ConfigurationPortalLibraryDocumentResponse,
	ConfigurationPortalPricingSheet,
	PricingSheetProduct,
	ConfigurationPortalFields,
} from '@oper-client/configuration-portal/common';
import { HttpHeaders } from '@angular/common/http';

export const CONFIGURATION_PORTAL_PRICING_SERVICE = new InjectionToken<PricingService>('CONFIGURATION_PORTAL_PRICING_SERVICE');

@Injectable()
export class PricingService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getLibraryDocumentTypes(): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/');
	}

	getLibraryDocumentType(definition: string): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/?search=' + definition);
	}

	getFilteredLibraryDocuments(documentTypeConfigurationId: string, creditProviderConfigurationId: string): Observable<any> {
		return this.apiService.get(
			'/configuration/documentationlibrarymodule/library-documents/library-documents/?document_type_config_id=' +
				documentTypeConfigurationId +
				'&credit_provider_config_id=' +
				creditProviderConfigurationId
		);
	}

	getLanguages(): Observable<any> {
		return this.apiService.get('/configuration/resources/language/');
	}

	getPeriodicities(): Observable<any> {
		return this.apiService.get('/configuration/resources/periodicity/');
	}

	getCostAmountType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-amount-type/');
	}

	getCostType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-type/');
	}

	getCostPromotionType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-promotion-type/');
	}

	getPricingSheets(): Observable<ConfigurationPortalPricingSheet[]> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/');
	}

	getPricingSheet(configurationId: string): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/' + configurationId);
	}

	getProducts(): Observable<PricingSheetProduct[]> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/');
	}

	getProduct(configurationId: string): Observable<PricingSheetProduct> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/' + configurationId);
	}

	createPricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.post('/configuration/pricingmodule/pricing-sheets/', pricingSheet);
	}

	updatePricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.put('/configuration/pricingmodule/pricing-sheets/' + pricingSheet.configurationId, pricingSheet);
	}

	uploadExcelPricer(excelPricer: ConfigurationPortalDocumentPayload): Observable<ExcelPricerResponse> {
		const formData = new FormData();
		formData.append('file_name', excelPricer.fileName || '');
		formData.append('file', excelPricer.file, excelPricer.fileName || '');

		const headers = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.post('/api/internaldocuments/upload/', formData, null, headers);
	}

	downloadExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.get(`/api/internaldocuments/documents/${configurationId}`);
	}

	deleteExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.delete(`/api/internaldocuments/documents/${configurationId}`);
	}

	downloadLibraryDocument(configurationId: string): Observable<ConfigurationPortalLibraryDocumentResponse> {
		return this.apiService.get(`/configuration/documentationlibrarymodule/library-documents/${configurationId}`);
	}

	createLibraryDocument(
		payload: ConfigurationPortalLibraryDocumentPayload,
		creditProvider: ConfigurationPortalFields | undefined
	): Observable<ConfigurationPortalLibraryDocumentResponse> {
		const body = {
			document_type: {
				summary: payload.documentType.summary,
				configuration_id: payload.documentType.configurationId,
			},
			language: {
				summary: payload.language.summary,
				configuration_id: payload.language.configurationId,
			},
			credit_provider: {
				summary: creditProvider.summary,
				configuration_id: creditProvider.configurationId,
			},
		};
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.post('/configuration/documentationlibrarymodule/library-documents/', body, null, headers);
	}

	uploadDocumentFile(configurationId: string, payload: ConfigurationPortalLibraryDocumentPayload): Observable<any> {
		const formData = new FormData();
		formData.append('file_name', payload.fileName || 'no-name');
		formData.append('file', payload.file, payload.fileName || 'no-name');
		const fileHeaders = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.post(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/upload/`,
			formData,
			null,
			fileHeaders
		);
	}

	uploadLibraryDocument(
		payload: ConfigurationPortalLibraryDocumentPayload,
		creditProvider: ConfigurationPortalFields | undefined
	): Observable<any> {
		return this.createLibraryDocument(payload, creditProvider).pipe(
			switchMap((response: ConfigurationPortalLibraryDocumentResponse) => this.uploadDocumentFile(response.configurationId, payload))
		);
	}

	deleteDocumentByConfigurationId(configurationId: string) {
		return this.apiService.delete(`/configuration/documentationlibrarymodule/library-documents/${configurationId}/`, null);
	}

	deactivateDocumentByConfigurationId(configurationId: string) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.patch(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/`,
			{
				active: false,
			},
			null,
			headers
		);
	}

	editLibraryDocument(
		configurationId: string,
		payload: ConfigurationPortalLibraryDocumentPayload
	): Observable<ConfigurationPortalLibraryDocumentResponse> {
		const body = {
			language: {
				summary: payload.language.summary,
				configuration_id: payload.language.configurationId,
			},
		};
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.patch(`/configuration/documentationlibrarymodule/library-documents/${configurationId}`, body, null, headers);
	}

	editDocumentFile(configurationId: string, payload: ConfigurationPortalLibraryDocumentPayload): Observable<any> {
		const formData = new FormData();
		if (payload.fileName) {
			formData.append('file_name', payload.fileName);
		}
		const fileHeaders = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.patch(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/upload/`,
			formData,
			null,
			fileHeaders
		);
	}

	patchLibraryDocument(configurationId: string, payload: ConfigurationPortalLibraryDocumentPayload): Observable<any> {
		return this.editLibraryDocument(configurationId, payload).pipe(
			switchMap((response: ConfigurationPortalLibraryDocumentResponse) => this.editDocumentFile(response.configurationId, payload))
		);
	}
}
