import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { AmountAllocation } from '@oper-client/shared/data-model';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';
import { AllocationRatioBarComponent } from '../allocation-ratio-bar/allocation-ratio-bar.component';
import { ProductAllocationsFormComponent } from '../product-allocations-form/product-allocations-form.component';

@Component({
	selector: 'oper-client-simulation-report-bullet-loan',
	standalone: true,
	imports: [TranslateModule, LowerCasePipe, AllocationRatioBarComponent, ProductAllocationsFormComponent],
	templateUrl: './simulation-report-bullet-loan.component.html',
	styleUrl: './simulation-report-bullet-loan.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportBulletLoanComponent {
	totalLoanAmount = input<number>(0);
	allocations = input<AmountAllocation[]>([]);
	loadDefaultOffers = output<AmountAllocation[]>();
	removeBulletLoan = output<void>();

	bulletLoanExpanded = signal<boolean>(this.allocations.length > 1);

	onAddBulletLoan(): void {
		this.loadDefaultOffers.emit([]);
		this.bulletLoanExpanded.set(true);
	}

	onRemoveBulletLoan(): void {
		this.removeBulletLoan.emit();
		this.bulletLoanExpanded.set(false);
	}
}
