import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { DocumentLibrary, DocumentLibraryUrlParams, PresignedUrl } from '@oper-client/shared/data-model';
import { Update } from '@ngrx/entity';

export const loadDocumentLibrarys = createAction('[DocumentLibrary] Load DocumentLibrarys', props<{ params: DocumentLibraryUrlParams }>());

export const loadDocumentLibrarysSuccess = createAction(
	'[DocumentLibrary] Load DocumentLibrarys Success',
	props<{ documentLibraries: Array<DocumentLibrary> }>()
);

export const loadDocumentLibrarysFailure = createAction(
	'[DocumentLibrary] Load DocumentLibrarys Failure',
	props<{ error: HttpErrorResponse }>()
);

export const createDocumentLibrary = createAction(
	'[DocumentLibrary] Create Document Library',
	props<{ document: Partial<DocumentLibrary>; file: File }>()
);
export const createDocumentLibrarySuccess = createAction(
	'[DocumentLibrary] Create Document Library Success',
	props<{ document: DocumentLibrary }>()
);
export const createDocumentLibraryFailure = createAction(
	'[DocumentLibrary] Create Document Library Failure',
	props<{ error: HttpErrorResponse }>()
);

// Upload file
export const uploadDocumentFile = createAction(
	'[DocumentLibrary] Upload Document file',
	props<{ documentId: number; file: File; fileName?: string }>()
);
export const uploadDocumentFileProgress = createAction(
	'[DocumentLibrary] Upload Document File progress',
	props<{ documentId: number; file: File; loaded: number; total: number }>()
);

export const uploadDocumentFileSuccess = createAction(
	'[DocumentLibrary] Upload Document File Success',
	props<{ document: Update<DocumentLibrary> }>()
);
export const uploadDocumentFileFailure = createAction(
	'[DocumentLibrary] Upload Document Failure',
	props<{ documentId: number; error: HttpErrorResponse }>()
);

export const deleteDocumentLibrary = createAction(
	'[AdditionalFinancingNeed/API] Remove Document Library',
	props<{ document: DocumentLibrary }>()
);
export const deleteDocumentLibrarySuccess = createAction(
	'[AdditionalFinancingNeed/API] Remove Document Library Success',
	props<{ document: DocumentLibrary }>()
);

export const deleteDocumentLibraryFailure = createAction(
	'[AdditionalFinancingNeed/API] Remove Document Library Failure',
	props<{ document: DocumentLibrary; error: HttpErrorResponse }>()
);

export const loadDocumentPresignedUrl = createAction(
	'[DocumentLibrary] Load Document Presigned Url',
	props<{ document: DocumentLibrary }>()
);

export const loadDocumentPresignedUrlSuccess = createAction(
	'[DocumentLibrary] Load Document Presigned Url Success',
	props<{ presignedUrl: PresignedUrl }>()
);

export const loadDocumentPresignedUrlFailure = createAction(
	'[DocumentLibrary] Load Document Presigned Url Failure',
	props<{ error: HttpErrorResponse }>()
);

export const resetDocumentsLibraryState = createAction('[DocumentLibrary] Reset Documents Library State');
