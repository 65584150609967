import { Pipe, PipeTransform } from '@angular/core';
import { CustomTranslations } from '@oper-client/shared/data-model';
import { LanguageService } from '@oper-client/shared/util-language';

@Pipe({
	name: 'customTranslation',
	pure: false,
})
export class CustomTranslationPipe implements PipeTransform {
	constructor(private readonly languageService: LanguageService) {}

	transform(customTranslations: CustomTranslations, name: string): string {
		if (customTranslations) {
			const lang = this.languageService.getCurrentLanguage();
			return customTranslations?.name ? customTranslations?.name[lang] : name;
		}

		return name;
	}
}
