<div class="header">
	<h3 class="title">
		{{ 'ç.feature.mortgageSimulator.result.bulletLoan.title' | translate }}
	</h3>
	@if (bulletLoanExpanded()) {
		<button class="button-tertiary" (click)="onRemoveBulletLoan()">Remove loan</button>
	} @else {
		<button class="button-tertiary" (click)="onAddBulletLoan()">
			{{ 'ç.feature.mortgageSimulator.result.bulletLoan.addLoan' | translate }}
		</button>
	}
</div>
@if (bulletLoanExpanded()) {
	<div class="body">
		<oper-client-allocation-ratio-bar [allocations]="allocations()"></oper-client-allocation-ratio-bar>
		<oper-client-product-allocations-form
			[allocations]="allocations()"
			(loadDefaultOffers)="loadDefaultOffers.emit($event)"
		></oper-client-product-allocations-form>
	</div>
}
