import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDocumentLibrary from './document-library.reducer';

export const getDocumentLibraryState = createFeatureSelector<fromDocumentLibrary.DocumentLibraryState>(
	fromDocumentLibrary.documentLibraryFeatureKey
);

export const getDocumentLibraries = createSelector(getDocumentLibraryState, fromDocumentLibrary.selectAll);
export const getDocumentPresignedUrl = createSelector(getDocumentLibraryState, (state) => state.presignedUrl);
export const loadDocumentLibrariesActionState = createSelector(getDocumentLibraryState, (state) => state.actions.loadDocumentLibraries);
export const loadDocumentPresignedUrlActionState = createSelector(
	getDocumentLibraryState,
	(state) => state.actions.loadDocumentPreSignedUrl
);
export const createDocumentActionState = createSelector(getDocumentLibraryState, (state) => state.actions.createDocumentLibrary);
