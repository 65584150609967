@if (loansAllocationForm && allocations()?.length > 1) {
	<form class="product-allocation-form" [formGroup]="loansAllocationForm">
		<p class="product-allocation-form__label">{{ allocations()[0].name }}</p>
		<p class="product-allocation-form__description">
			{{ 'ç.feature.mortgageSimulator.result.bulletLoan.baseProduct.description' | translate }}
		</p>
		<div class="product-allocation-form__row">
			<div class="product-allocation-form__amount-group">
				<oper-client-input-currency [value]="baseProductAmount?.value" (changed)="baseProductAmountSubject.next($event)" />
			</div>
			<div class="product-allocation-form__percentage-group">
				<oper-client-input-percentage
					[value]="baseProductPercentage?.value"
					(changed)="baseProductPercentageSubject.next($event)"
				/>
			</div>
		</div>
		<p class="product-allocation-form__label">
			{{ allocations()[1].customTranslations | customTranslation : allocations()[1].name }}
		</p>
		<p class="product-allocation-form__description">
			{{ 'ç.feature.mortgageSimulator.result.bulletLoan.bulletProduct.description' | translate }}
		</p>
		<div class="product-allocation-form__row">
			<div class="product-allocation-form__amount-group">
				<oper-client-input-currency [value]="bulletProductAmount?.value" (changed)="bulletProductAmountSubject.next($event)" />
			</div>
			<div class="product-allocation-form__percentage-group">
				<oper-client-input-percentage
					[value]="bulletProductPercentage?.value"
					(changed)="bulletProductPercentageSubject.next($event)"
				/>
			</div>
		</div>
	</form>
}
