import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { ConfigurationPortalProductDiscount } from '@oper-client/configuration-portal/common';

export const CONFIGURATION_PORTAL_DISCOUNT_GROUP_SERVICE = new InjectionToken<DiscountGroupService>(
	'CONFIGURATION_PORTAL_DISCOUNT_GROUP_SERVICE'
);

@Injectable()
export class DiscountGroupService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getDiscountGroup(): Observable<ConfigurationPortalProductDiscount[]> {
		return this.apiService.get('/configuration/resources/discount-type/');
	}
	updateDiscountGroup(
		configurationId: string,
		discountGroup: Partial<ConfigurationPortalProductDiscount>
	): Observable<ConfigurationPortalProductDiscount> {
		return this.apiService.patch('/configuration/resources/discount-type/' + configurationId, discountGroup);
	}

	deleteGenericAcceptanceRule(configurationId: string): Observable<ConfigurationPortalProductDiscount> {
		return this.apiService.delete('/configuration/resources/discount-type/' + configurationId);
	}

	createDiscountGroup(discountGroup: Partial<ConfigurationPortalProductDiscount>): Observable<ConfigurationPortalProductDiscount> {
		return this.apiService.post('/configuration/resources/discount-type/', discountGroup);
	}
}
