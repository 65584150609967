import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { LowerCasePipe, NgClass } from '@angular/common';
import { AmountAllocation } from '@oper-client/shared/data-model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-allocation-ratio-bar',
	standalone: true,
	imports: [UtilFormattingModule, NgClass, TranslateModule, LowerCasePipe],
	templateUrl: './allocation-ratio-bar.component.html',
	styleUrl: './allocation-ratio-bar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllocationRatioBarComponent {
	allocations = input<AmountAllocation[]>([]);
	productColors = ['#29584E', '#C1D8C9'];

	readonly total = computed(() => {
		return this.allocations()?.reduce((sum, allocation) => sum + (allocation.amount ?? 0), 0) ?? 0;
	});

	calculateProgress(value: number): number {
		return this.total() > 0 ? Math.max(Number(((value / this.total()) * 100).toFixed(2)), 0) : 0;
	}
}
